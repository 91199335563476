<template>
  <div class="wrapper">
    <div class="main-panel pt-5">
    <!-- <div class="main-panel py-5"> -->
      <div class="content align-items-center my-3">
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
              <div class="card">
                <div class="content">
                  <div class="row m-4">
                    <div class="col-md-12">
                      <div class="card-description color-text-phila text-center">
                        <img src="assets/img/Heritage.new.PNG" alt="" srcset="" class="img-fluid" style="width:30%">
                        <br>
                        Bienvenue à Phila Cité d'exaucement
                        <p v-if="this.form.step_one && !this.form.step_two" class="mt-5">
                          <span class="display-4 text-dark">Ouvriers Partenaires</span>
                          <!-- <span class="display-4 text-dark"> Heritage d'influence</span> -->
                        </p>
                      </div>
                      <div v-if="this.form.step_one && !this.form.step_two" class="form-group text-center mb-3">
                        <label>Posez un geste qui dure,<br> donnez maintenant pour des générations</label>
                      </div>
                      <div v-else class="form-group text-center mb-2 text-dark">
                        <span class="text-success small mt-2">Souscription éffectué avec succès !!!</span> <br>
                        <lottie-player class="text-center"
                          :src="this.$store.state.app_url_img_localhost + '/assets/lotties/success/success2.json'"
                          background="transparent" speed="1" style="width: 100%; height: 140px;" loop
                          autoplay></lottie-player>

                        <p style="font-size:13px;" class="mt-2">Vous serez notifier à chacune de vos paiements et serez
                          informé de l'avancement de toute chose.
                        </p>
                        <p style="font-size:13px;">Que Dieu vous bénisse richement.
                        </p>
                        <p style="font-size:13px;">Pasteur, Athom's Mbuma
                        </p>
                        <p class="text-center">
                          <a href="" @click.prevent="newsSouscripteur" class="border-bottom small newsSous">+ Nouvelle
                            souscription</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.form.step_one && !this.form.step_two" class="col-lg-6 col-md-6">
              <div class="card">
                <div class="header">
                  <h4 class="title">Fiche de Souscription</h4>
                </div>
                <div class="content">
                  <form class="mx-4" @submit.prevent="storeSouscripteur">

                    <div class="row">
                       <div class="col-md-12">
                          <span  class="text-secondary">Coordonnées Personnel</span>
                          <hr>
                        </div>
                      <div class="col-md-8">
                        <div class="form-group">
                          <label>Nom Complet (Prenom, nom et postnom) <span class="text-danger">*</span></label>
                          <input type="text" v-model="this.form.names" class="form-control border-input"
                            placeholder="Prenom, Nom et Postnom">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Sexe <span class="text-danger">*</span></label>
                          <select v-model="this.form.profiler_sex" class="form-control border-input">
                            <option value="M">Homme</option>
                            <option value="F">Femme</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Téléphone <span class="text-danger">*</span> (ex.0810000000)</label>
                          <input type="text" v-model="this.form.profiler_phone" class="form-control border-input"
                            placeholder="Téléphone">
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Email</label>
                          <input type="email" v-model="this.form.profiler_email" class="form-control border-input"
                            placeholder="Email">
                        </div>
                      </div>

                      <div class="col-md-10">
                        <div class="form-group">
                          <label>Ministère<span class="text-danger">*</span></label>
                          <select v-model="this.form.minister_id" class="form-control border-input">
                            <option  v-for="(minist, index) in ministers" :key="index" :value="minist.id">{{ minist.minister_nom.toUpperCase() }}</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div v-if="!this.lottieloading" class="row justify-content-center">
                      <div class="col-md-12">
                        <span  class="text-dark">Souscription</span>
                        <hr>
                      </div>
                      <div class="col-md-11">
                        <div class="row justify-content-center">
                          <div class="col-12">
                            <div class="form-group  d-flex">
                                <span>
                                  <input  type="radio"  name="typesouscription" v-model="this.form.self_souscription"  value="1" checked>
                                  Mensuel
                                </span> 
                                <span class="mx-5">
                                  <input  type="radio" name="typesouscription" v-model="this.form.self_souscription"  value="2">
                                  Ponctuel
                                </span> 
                            </div>
                          </div>
                          <div class="col-md-11">
                            <div class="form-group">
                              <div class="row">
                                <div class="col-12">
                                  <label>Montant souscription souhaité <span class="text-danger">*</span></label>
                                </div>
                              </div>
                              <div class="row">
                                <span class="border px-md-3 py-md-2 p-2 m-2  border-radius" style="border-radius:5px;">
                                  <input  type="radio" name="pricesouscription" value="10" v-model="this.form.projet_souscripteur_price_estimation" @click="notActiveOthersPrice">
                                  10$
                                </span> 
                                <span class="border  px-md-3 py-md-2 p-2  m-2   border-radius" style="border-radius:5px;">
                                  <input  type="radio" name="pricesouscription" value="20"  v-model="this.form.projet_souscripteur_price_estimation" @click="notActiveOthersPrice">
                                  20$
                                </span> 
                                <span class="border  px-md-3 py-md-2 p-2  m-2   border-radius" style="border-radius:5px;">
                                  <input  type="radio" name="pricesouscription" value="50"  v-model="this.form.projet_souscripteur_price_estimation" @click="notActiveOthersPrice">
                                  50$
                                </span> 
                                <span class="border  px-md-3 py-md-2 p-2  m-2   border-radius" style="border-radius:5px;">
                                  <input  type="radio" name="pricesouscription" value="100"  v-model="this.form.projet_souscripteur_price_estimation" @click="notActiveOthersPrice">
                                  100$
                                </span> 
                              </div>
                              <div class="row">
                                <span class="border px-md-3 py-md-2 p-2 m-2  border-radius" style="border-radius:5px;">
                                  <input  type="radio" name="pricesouscription" value="200" v-model="this.form.projet_souscripteur_price_estimation" @click="notActiveOthersPrice">
                                  200$
                                </span> 
                                <span class="border px-md-3 py-md-2 p-2 m-2   border-radius" style="border-radius:5px;">
                                  <input  type="radio" name="pricesouscription" value="500"  v-model="this.form.projet_souscripteur_price_estimation" @click="notActiveOthersPrice">
                                  500$
                                </span> 
                                <span class="border px-md-3 py-md-2 p-2 m-2   border-radius" style="border-radius:5px;">
                                  <input  type="radio" name="pricesouscription" value="1000"  v-model="this.form.projet_souscripteur_price_estimation" @click="notActiveOthersPrice">
                                  1000$
                                </span> 
                              </div>
                              <div class="row align-items-center mt-md-2 mt-3">
                                <div class="col-md-4 col-6" style="padding-left: 5px !important;">
                                  <span class="border p-2  mr-2   border-radius" style="border-radius:5px;cursor:pointer;" @click.prevent="activeOthersPrice">
                                    Autre Montant
                                  </span> 
                                </div>
                                <div v-if="this.form.active_other_montant" class="col-md-3 col-4">
                                  <input type="number"  class="form-control border-input" v-model="this.form.projet_souscripteur_price_estimation">
                                </div>
                                <div v-if="this.form.active_other_montant" class="col-md-1 col-2">
                                  / $
                                </div>
                              </div>
                              <div class="row py-md-0 py-3 d-none">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label>Depuis quel ville <span class="text-danger">*</span> </label>
                                    <input type="text" v-model="this.form.city_name" class="form-control border-input"
                                      placeholder="Ville">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row  align-items-center justify-content-between my-md-0 my-2">
                      <div class="col-md-6 order-md-1 order-2 mt-5 mt-md-0">
                        <div class="text-md-left text-center"  style="color:#6c757d;">
                          <i class="ti-help-alt"></i>
                          <router-link to="/payment"  class="text-secondary border-bottom">
                           Vérifier mes paiements
                          </router-link>
                        </div>
                      </div>
                      <div class="col-md-6 order-md-2 order-1 text-md-right text-center">
                        <div class="text-md-right text-center" :class="!this.lottieloading ? '' : 'my-4'">
                          <button @click.prevent="to_submit_form" v-if="!this.lottieloading" type="submit"
                            class="btn btn-danger btn-fill btn-wd float-md-right">Souscrire</button>
                          <div class="text-center">
                            <lottie-player v-if="this.lottieloading && !this.form.step_two" class="text-center"
                              :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                              background="transparent" speed="1" style="width: 100%; height: 90px;" loop
                              autoplay></lottie-player>
                            <span v-if="this.lottieloading && !this.form.step_two" class="small">Traitement en cours</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-center">
              <nav>
                <ul>
                  <li>
                    <div  class="d-md-flex align-items-center">
                      <img src="assets/img/Phila-3-1024x1024.png" alt="" srcset="" class="img-fluid mr-2" style="width:5%">
                      <a href="https://ce.church" class="mt-md-1">
                        Phila c.e
                      </a>
                    </div>
                  </li>
                  <!-- <li>
                    <a href="">
                      Infos
                    </a>
                  </li> -->
                </ul>
              </nav>
            </div>
            <div class="col-md-6 text-center">
              <div class="copyright">
                  &copy; {{ new Date().getFullYear() }}, produit par <a href="" class="secondary text-secondary">Phila c.e</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'LoadPage',

  data() {
    return {
      user: null,
      ministers: [],
      form: {
        names: null,
        minister_id: null,
        profiler_etat_civil: "Non défini",
        profiler_phone: null,
        profiler_email: null,
        profiler_sex: null,
        devise_id: null,
        projet_souscripteur_price_estimation: null,
        city_name: null,
        type_souscription_id: null,
        self_souscription: null,
        step_one: true,
        step_two: false,
        active_other_montant:false
      },
      requesting: false,
      step: 1,
      checkForm: false,
      lottieloading: false,
      settiming: null
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.data_ministers();

    setInterval(() => {
      this.data_ministers();
    }, 180000);
  },
  methods: {

    async data_ministers() {
      await axios.get(this.$store.state.app_url_dev + "ministers").then(({ data }) => {
       if (data.status == 1) {
          this.ministers = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async storeSouscripteur() {

      // this.form.city_name ="Kinshasa";

      if (this.form.self_souscription != null) {
        this.form.type_souscription_id = 2;
      } else {
        this.form.type_souscription_id = 1;
      }

      if (this.form.names != null && this.form.names.toString().trim() != "" && this.form.profiler_phone != null && this.form.profiler_phone.toString().trim() != "" && this.form.projet_souscripteur_price_estimation != null && this.form.projet_souscripteur_price_estimation.toString().trim() != "" && this.form.profiler_sex != null && this.form.profiler_sex.toString().trim() != "" && this.form.city_name != null && this.form.city_name.toString().trim() != "") {

        this.form.devise_id = 2;

        await axios.post(this.$store.state.app_url_dev + "souscripteur", this.form).then(({ data }) => {
          if (data.status == 1) {
            // var message = "Bonjour " + (this.form.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") + (this.form.names.toString().trim().toUpperCase()) + ".\n Votre souscription de " + this.form.projet_souscripteur_price_estimation.toString() +  "USD " + (this.form.type_souscription_id == 1 ? '/Mois' : ' ') + " vient d'être fait pour le projet Ouvriers Partenaires.\n Contacts :+243828501407,heritage-influence@ce.church";
            // var message = "Bonjour " + (this.form.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") + (this.form.names.toString().trim().toUpperCase()) + ".\n Nous vous remercions pour votre elan de coeur envers Phila CE. Et que Dieu vous donne les moyens d'honorer votre contribution.";


            // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());

            // axios.get(urlgetsms).then(() => { });

            var message = null;
            axios.get(this.$store.state.app_url_dev + "sms_partenaires").then(({ data }) => {
              if (data.status == 1) {
                this.dt_notifications = data.data;

                  if(data.data[0].sms_partenaire_souscription !=null && data.data[0].sms_partenaire_souscription !=""){
                    message = data.data[0].sms_partenaire_souscription;
                  }else{
                    message = "Bonjour " + (this.form.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") + (this.form.names.toString().trim().toUpperCase()) + ".\n Nous vous remercions pour votre elan de coeur envers Phila CE. Et que Dieu vous donne les moyens d'honorer votre contribution.";
                  }

                  if(this.form.profiler_phone.toString() !=null && this.form.profiler_phone.toString().trim() !=""){
                    this.gb_notifications_send(this.form.profiler_phone.toString().trim(),message);
                  }

              } else {
                this.toast.error(data.message, {
                  timeout: 6000
                });
              }
            }).catch(function (error) {
              console.log(error);
              this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                timeout: 2000
              });
            });





            setTimeout(() => {
              this.form.step_one = false;
              this.form.step_two = true;
              this.lottieloading = false;

              this.settiming = setInterval(() => {
                this.newsSouscripteur();
              }, 6000);

            }, 4500);
          } else {
            // this.toast.error(data.message, {
            //   timeout: 6000
            // });
          }
        }).catch(function (error) {
          console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
      } else {
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    to_submit_form() {
      this.form.city_name ="Kinshasa";
      if (this.form.names != null && this.form.names.toString().trim() != "" && this.form.profiler_phone != null && this.form.profiler_phone.toString().trim() != "" && this.form.projet_souscripteur_price_estimation != null && this.form.projet_souscripteur_price_estimation.toString().trim() != "" && this.form.profiler_sex != null && this.form.profiler_sex.toString().trim() != "" && this.form.city_name != null && this.form.city_name.toString().trim() != "") {
        if (this.Online()) {
          this.lottieloading = true;
          this.storeSouscripteur();
        }
      } else {
        this.checkForm = true;
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    reseRefreshForm() {
      this.form.names = null;
      this.form.profiler_etat_civil = "Non défini";
      this.form.profiler_phone = null;
      this.form.profiler_email = null;
      this.form.profiler_sex = null;
      this.form.devise_id = null;
      this.form.projet_souscripteur_price_estimation = null;
      this.form.city_name = null;
      this.form.type_souscription_id = null;
      this.form.self_souscription = null;
    },

    activeOthersPrice() {
      this.form.active_other_montant = true;
      this.form.projet_souscripteur_price_estimation = null;
    },

    notActiveOthersPrice() {
      this.form.active_other_montant = false;
    },

    newsSouscripteur() {
      clearInterval(this.settiming);
      this.reseRefreshForm();
      this.form.step_one = true;
      this.form.step_two = false;
      this.lottieloading = false;
      this.settiming = null;
    },

    changesexe(typesexe) {
      if (this.isSexe) {
        this.isSexe = false;
      } else {
        this.isSexe = true;
      }
      this.form.identity_sex = typesexe;
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },

    getNumberFormatSms(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value.substring(1, value.length);
        } else if (value.length == 12) {
          return value;
        } else if (value.length == 10) {
          return '243' + value.substring(1, value.length);
        }
      }
    },
  }
}
</script>
