<template>
  <div class="wrapper">
    <div class="main-panel py-5">
      <div class="content align-items-center my-3">
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div v-if="this.form.step_one && this.form.block_one && !this.form.block_two" class="col-lg-6 col-md-6">
              <div class="card">
                <div class="content">
                  <div class="row m-4">
                    <div class="col-md-12">
                      <div class="card-description color-text-phila text-center">
                        <img src="assets/img/Heritage.new.PNG" alt="" srcset="" class="img-fluid" style="width:30%">
                        <br>
                        Bienvenue à Phila Cité d'exaucement
                        <p v-if="this.form.step_one" class="mt-5">
                          <!-- <span class="display-4 text-dark"> Heritage d'influence</span> -->
                          <span class="display-4 text-dark"> Ouvriers Partenaires</span>
                        </p>
                      </div>
                      <div v-if="this.form.step_one" class="form-group text-center mb-3 mt-2">
                        <label>Posez un geste qui dure,<br> donnez maintenant pour des générations</label>
                        <form v-if="this.form.step_one && !this.form.step_two && !this.form.step_three" class="mx-4" @submit.prevent="storeSouscripteur">
                          <div class="row justify-content-center text-left">
                            <div class="col-md-10">
                              <div class="form-group">
                                <label class="text-left">Téléphone <span class="text-danger">*</span></label>
                                <input type="text" v-model="this.form.phone" class="form-control border-input"
                                  placeholder="Téléphone">
                              </div>
                            </div>
                          </div>
                        </form>
                        <form v-if="this.form.step_one && this.form.step_two && !this.form.step_three" class="mx-4">
                          <div class="row justify-content-center text-left">
                            <div class="col-md-10">
                              <div class="form-group text-center ">
                                <label  class="font-weight-bold text-dark">Entrez le code  vérification récu</label>
                                <input type="text" v-model="this.form.code" class="form-control border-input"
                                  placeholder="code de vérification">
                              </div>
                            </div>
                          </div>
                        </form>

                        <div class="d-flex row align-items-center text-center my-2">
                          <div class="col-12 text-center" :class="!this.lottieloading ? '' : 'my-4'">
                            <button v-if="this.form.step_one && !this.form.step_two && !this.form.step_three && !this.lottieloading" @click.prevent="to_submit_verif_phone_number" type="submit"
                              class="btn btn-danger btn-fill btn-wd">Vérifier numéro</button>
                            <button v-if="this.form.step_one && this.form.step_two && !this.form.step_three && !this.lottieloading" @click.prevent="to_submit_verif_code_send" type="submit"
                              class="btn btn-danger btn-fill btn-wd">Confirmer code</button> 
                             
                            <div class="text-center">
                              <lottie-player v-if="this.lottieloading" class="text-center"
                                :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                background="transparent" speed="1" style="width: 100%; height: 90px;" loop
                                autoplay></lottie-player>
                              <span v-if="this.lottieloading " class="small">Traitement en cours</span>
                            </div>
                            <div class="mt-5">
                              <router-link to="/"  class="text-secondary border-bottom">
                                Fermer
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.form.step_one && this.form.step_two  && this.form.step_three && !this.form.block_one && this.form.block_two" class="col-lg-6 col-md-6">
              <div class="card">
                <div class="header d-flex align-items-center">
                  <img src="assets/img/Heritage.new.PNG" alt="" srcset="" class="img-fluid" style="width:15%">
                  <h5 class="title">Mes Cotisations </h5>
                </div>
                <div class="content">
                  <div v-if="this.cotisations.length > 0 && !this.lottieloading" class="content table-responsive table-full-width mb-4" style="height:450px; overflow-y: auto;">
                      <table class="table table-striped table-hover"  id="table_payments">
                          <thead class="small">
                              <th>ID</th>
                            <th>Détails Paiement</th>
                          </thead>
                          <tbody>
                            <tr v-for="(payment, index) in cotisations.slice(0, 10)" :key="index" class="col">
                              <td>{{ index + 1 }}</td>
                              <td>
                                <div class="row">
                                  <div class="col-12">
                                    <span style="font-weight: bold;">{{ payment.payment }} {{ payment?.devise?.devise_short }}</span> <br/>
                                    <span>{{ payment?.periodicity?.periodicity_title }}</span> 
                                    <span> ({{ payment?.payment_date }})</span> 
                                    <!-- <span>{{ this.bg_format_date(payment?.payment_date, 'MM,DD YYYY') }} ({{ payment?.payment_date }})</span>  -->
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                      </table>
                  </div>
                  <div  v-if="this.cotisations.length == 0 && !this.lottieloading" class="row justify-content-center">
                    <div class="col-12 text-center">
                      <p class="text-secondary" style="font-size: 15px;">Vous ne disposez d'aucune cotisation faites à ce jour.</p>
                    </div>
                  </div>
                  <lottie-player v-if="this.cotisations.length == 0 && this.lottieloading" class="text-center"
                  :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                  background="transparent" speed="1" style="width: 100%; height: 90px;" loop
                  autoplay></lottie-player>
                  <div class="mb-3">
                    <div class="row justify-content-center">
                      <div class="col-4 text-center">
                          <router-link to="/"  class="text-secondary border-bottom">
                           Fermer
                          </router-link>
                      </div>
                      <div v-if="this.cotisations.length > 0 && !this.lottieloading" class="col-5">
                        <a class="border px-2 py-2 border-rounded float-right text-dark" href="" style="border-radius: 10px;font-size:13px;" @click.prevent="generatePdf">
                          <i class="ti-download small"></i>
                          Télécharger le rapport</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <footer class="footer">
        <div class="container">
          <nav class="pull-left">
            <ul>
              <li>
                <a href="https://ce.church">
                  Phila c.e
                </a>
              </li>
              <!-- <li>
                <a href="">
                  Infos
                </a>
              </li> -->
            </ul>
          </nav>
           <div class="copyright pull-right">
              &copy; {{ new Date().getFullYear() }}, produit par <a href="" class="secondary text-secondary">Phila c.e</a>
          </div>
        </div>
      </footer>

    </div>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/fr";
import axios from 'axios';
import { useToast } from "vue-toastification";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export default {
  name: 'LoadPage',

  data() {
    return {
      user: null,
      cotisations: [],
      form: {
        phone: null,
        code: null,
        step_one: true,
        step_two: false,
        step_three: false,
        code_confirmation: "",
        test_code_verif: 0,
        block_one: true,
        block_two: false
      },
      step: 1,
      lottieloading: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  methods: {

    async to_submit_verif_phone_number() {
      if (this.form.phone != null && this.form.phone.trim() != "") {
        this.lottieloading = true;
        var requestData = {
          "user_pseudo" : this.form.phone
        }
        
        await axios.post(this.$store.state.app_url_dev + "sh-pseudo-user", requestData).then(({ data }) => {
          if (data.status == 1) {
            var codeConfirmation = (Math.random() * (2023 - 1999) + Math.random()) * 10000;
            this.user = data.data
            this.form.code_confirmation = codeConfirmation.toString().substring(0, 4);
            var message = "Votre code de confirmation  : " + this.form.code_confirmation;
      
            let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.phone.toString().trim());
      
            axios.get(urlgetsms).then(() => { });
            this.form.step_two = true;
            this.lottieloading = false;
          } else {
            this.toast.error("Désolé, ce compte n'hesite pas.", {
              timeout: 5000
            });
            this.form.step_two = false;
            this.lottieloading = false;
            this.form.phone = "";
          }
        }).catch(function (error) {
          console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
      } else {
          this.toast.error("Veuillez compléter un numéro de téléphone", {
          timeout: 5000
        });
      }
    },

    async to_submit_verif_code_send() {
      if (this.form.code_confirmation != null && this.form.code_confirmation.trim() != "") {
        if (this.form.code != null && this.form.code.trim() != "") {
          this.lottieloading = true;

          if (this.form.test_code_verif  < 2) {
            if (this.form.code_confirmation == this.form.code ) {

              this.form.step_one = true;
              this.form.step_two = true;
              this.form.step_three = true;
              this.form.block_one = false;
              this.form.block_two = true;
              var requestData = {
                  "profiler_phone": this.form.phone
                }

              this.isListCotisations(requestData);
              setInterval(() => {
                this.isListCotisations(requestData);
              }, 120000);

            } else {
              this.toast.error("Code de vérification érronné, réessayer !!!", {
                timeout: 5000
              });
              this.lottieloading = false;
              this.form.code = "";
              this.form.test_code_verif++;
            }
          }else{
            if (this.form.test_code_verif == 2 || this.form.test_code_verif >=2) {
                this.toast.error("Tentative de vérification épuisé. Veuillez réessayer plus tard !!!", {
                  timeout: 5000
                });
                setTimeout(() => {
                  this.lottieloading = false;
                  this.form.step_two = false;
                  this.lottieloading = false;
                  this.form.phone = "";
                  this.$router.push({ path: '/' })
                }, 5001);
            } 
          }
        } else {
            this.toast.error("Veuillez compléter le code de vérification", {
            timeout: 5000
          });
        }
      } else {
          this.toast.error("Veuillez renvoyer le code de confirmation", {
            timeout: 5000
          });
      }
    },

    async isListCotisations(requestData) {
      this.lottieloading = true;
      await axios.post(this.$store.state.app_url_dev + "souscripteur_payments", requestData).then(({ data }) => {
        if (data.status == 1) {
          this.cotisations = data.data;
          this.lottieloading = false;
        } else {
          this.toast.error("Désolé,Aucune donnée disponible. Veuillez réessayer plus tard", {
            timeout: 5000
          });
        }
      }).catch(function (error) {
        console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
        this.lottieloading = false;
      });
    },

    generatePdf() {

      setTimeout(() => {
        var pdf = new jsPDF({ putOnlyUsedFonts: true });
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(14);
        pdf.text("RAPPORT COTISATION 7KP", 70, 20);

        pdf.setFontSize(11);
        autoTable(pdf, {
          html: "#table_payments",
          margin: { top: 60, left:40, right:40 },
          styles: { fontSize: 10, fontWeight: "bold", AlignItems: "center" },
        });

        pdf.setFontSize(12);
        pdf.setFont("helvetica", "none");
        pdf.text(
          "PHILA - PROJET 7KP",
          200,
          270,
          null,
          null,
          "right"
        );
        pdf.setFontSize(11);
        pdf.text(
          "Pour " + this.user?.profiler?.profiler_prenom.toUpperCase()  + "  " + this.user?.profiler?.profiler_nom.toUpperCase(),
          200,
          275,
          null,
          null,
          "right"
        );
        pdf.setFontSize(10);
        pdf.text(
          "Imprimé, le " + moment(String(new Date())).format("YYYY-MM-DD à HH:mm:ss"),
          200,
          280,
          null,
          null,
          "right"
        );
        pdf.text(
          "Contacts :+243828501407,heritage-influence@ce.church",
          200,
          285,
          null,
          null,
          "right"
        );
        pdf.save("cotisations_projet_7kp.pdf");
      }, 1000);
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },

    getNumberFormatSms(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value.substring(1, value.length);
        } else if (value.length == 12) {
          return value;
        } else if (value.length == 10) {
          return '243' + value.substring(1, value.length);
        }
      }
    },
  }
}
</script>
